import "core-js/stable";
import "regenerator-runtime/runtime";
import 'bootstrap';
import moment from 'moment/moment';
import Chartkick from "chartkick";
import "chartkick/chart.js";
import 'select2/dist/css/select2.css';
import 'select2';
import Trix from "trix";
import "@rails/actiontext";
import "./richtext.js";
import "../stylesheets/application.scss";


import Rails from "@rails/ujs";

Rails.start();
require("turbolinks").start();
require("@rails/activestorage").start()

global.$ = require("jquery");
global.Rails = Rails;

// require('jquery');
require("jquery-ui");
require("cocoon");

// document.addEventListener('DOMContentLoaded', () => {
window.addEventListener('turbolinks:load', () => {
  $('.select2').select2({
    tags: true,
    tokenSeparators: [',', ' ']
  });
});

import "controllers"
